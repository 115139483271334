<template>
  <van-button
    :size="size || undefined"
    :type="type || undefined"
    :disabled="disabled || running"
    style="width:80px"
  >
    <span v-show="!running">{{ text }}</span>
    <van-count-down
      v-show="running"
      ref="countDown"
      millisecond
      :time="total"
      :auto-start="false"
      format="(ss)秒"
      style="color:white;"
      @finish="finish"
    />
  </van-button>
</template>

<script>
export default {
  props: {
    size: { type: String, default: 'small' },
    type: { type: String, default: 'primary' },
    text: { type: String, default: '获取密码' },
    total: { type: Number, default: 60 * 1000 },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      running: false
    }
  },
  methods: {
    start() {
      this.running = true
      this.$refs.countDown.start()
    },
    finish() {
      this.running = false
      this.$refs.countDown.reset()
    }
  }
}
</script>

<style scoped>

</style>
