<template>
  <div class="bg">
    <div class="login">
      <div class="login-title">
        <span>预约审车</span>
        <span>{{ $appCfg.tenant_name }}</span>
      </div>

      <van-cell-group>
        <van-field v-model="fmData.phone" type="tel" label="手机号" placeholder="手机号" maxlength="11" />
        <van-field v-model="fmData.captcha" label="校验码" placeholder="校验码" maxlength="5">
          <div slot="button" style="line-height:0px;font-size:0px">
            <img :src="captchaImgUrl" width="120" height="30" @click="refreshCaptcha">
          </div>
        </van-field>
        <van-field v-model="fmData.pwd" type="password" label="密码" placeholder="密码" maxlength="20">
          <CountDownButton ref="cdBtn" slot="button" :disabled="sendPwdBtnDisabled" @click.native="sendPwd" />
        </van-field>
      </van-cell-group>

      <div class="btns-col" style="padding:var(--mp) 0px;">
        <van-button type="primary" :disabled="loginBtnDisabled" @click="login">登 陆 / 注 册</van-button>
      </div>

      <Copyright />
    </div>
  </div>
</template>

<script>
import AuthApi from '@/apis/AuthApi'
import Copyright from '@/components/Copyright'
import CountDownButton from '@/components/CountDownButton'
export default {
  components: { Copyright, CountDownButton },
  data() {
    return {
      captchaImgUrl: '',
      sendPwdBtnDisabled: true,
      loginBtnDisabled: true,
      fmData: {
        rid: AuthApi.genRandomId(),
        phone: '',
        pwd: '',
        captcha: ''
      }
    }
  },
  watch: {
    'fmData.phone': function() { this.changeBtnStatus() },
    'fmData.captcha': function() { this.changeBtnStatus() },
    'fmData.pwd': function() { this.changeBtnStatus() }
  },
  created() {
    this.captchaImgUrl = AuthApi.getCaptchaImgUrl(this.fmData.rid)
  },
  methods: {
    refreshCaptcha() {
      this.captchaImgUrl = AuthApi.getCaptchaImgUrl(this.fmData.rid)
    },
    sendPwd() {
      if (!this.fmData.phone || !this.fmData.captcha) {
        this.$toast.fail('手机号或校验码为空！')
        return
      }
      this.$refs.cdBtn.start()
      AuthApi.sendPhonePwd(this.fmData.rid, this.fmData.phone, this.fmData.captcha).then(rsp => {
        this.$toast.success('密码已发送！')
      }).catch(err => {
        this.$refs.cdBtn.finish()
        this.$toast.fail(err.message)
      })
    },
    login() {
      this.$store.dispatch('reme/doReme', { rememberMe: true, phone: this.fmData.phone })
      this.$store.dispatch('auth/custLogin', this.fmData).then(() => {
        if (!this.$store.state.auth.user.name) {
          this.$router.replace('/cust-my/my')
        } else {
          this.$router.replace('/cust-vl/mng')
        }
      }).catch(err => {
        this.$toast.fail(err.message)
      })
    },
    changeBtnStatus() {
      this.sendPwdBtnDisabled = !(this.fmData.phone && this.fmData.captcha)
      this.loginBtnDisabled = !(this.fmData.phone && this.fmData.captcha && this.fmData.pwd)
    }
  }
}
</script>

<style scoped>
.bg{
  width: 100vw;
  height: 100vh;
  background: var(--gradient-bg);
}
.login{
  padding: 15vh var(--mp) var(--mp) var(--mp);
}
.options{
  display: flex;
  justify-content: space-between;
  padding: var(--mp) 0px;
  color: var(--color-text-sub);
}
.options >>> .van-checkbox__label{
  color: var(--color-text-sub);
}
.login-title{
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  color: white;
  padding: var(--mp);
  display: flex;
  flex-direction: column;
}
.login-title > span:last-child {
  font-size: .875rem;
  color: white;
  padding-top: .25rem;
}
</style>
